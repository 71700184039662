<template>
    <div class="view-page customer-maintenance" v-if="hasTicket('cust')">
        <div class="page-header">
            <h1>
                取引先マスタ
                <button
                    class="addButton"
                    @click="showEditor()"
                    v-if="hasTicket('cust-c')"
                >
                    ＋
                </button>
            </h1>

            <OpSearchTextField
                label="ID / CD / 名称 / 略称 / インデックス / 備考"
                v-model.trim="query.words"
                maxlength="80"
                @changed-query="search"
            />
            <button @click="showFilterPanel">
                <IconBase iconName="filter" :visibleName="true"
                    ><IconFilter
                /></IconBase>
            </button>
            <!-- <OpDateTextField
                    label="基準日"
                    v-model.trim="query.rdate"
                    name="rdate"
                    @input="search"
                />
                <OpCheckbox
                    label="予定（無効）"
                    v-model="query.rdiv.future"
                    name="future"
                    @input="search"
                />
                <OpCheckbox
                    label="有効"
                    v-model="query.rdiv.current"
                    name="current"
                    @input="search"
                />
                <OpCheckbox
                    label="過去（無効）"
                    v-model="query.rdiv.past"
                    name="past"
                    @input="search"
                /> -->
        </div>
        <OpModal
            v-if="showFilter"
            @close="showFilter = false"
            title="フィルター"
        >
            <div class="detail-filters">
                <!-- 有効期間 開始 -->
                <div style="display:flex;">
                    <div class="item">
                        <OpDateRangeTextField
                            label="有効期間（開始日）"
                            v-model="qfilter.start_date"
                            name="start_date"
                        />
                    </div>
                    <!-- 有効期間 終了 -->
                    <div class="item" style="padding-left:2rem;">
                        <OpDateRangeTextField
                            label="有効期間（終了日）"
                            v-model="qfilter.end_date"
                            name="end_date"
                        />
                    </div>
                </div>
                <div class="item-group cust-attr">
                    <div class="title">種別（複数選択可）</div>
                    <div class="item">
                        <OpCheckbox
                            label="受注先"
                            v-model.trim="qfilter.salesorder_flag"
                            maxlength="100"
                            name="salesorder_flag"
                        />

                        <OpCheckbox
                            label="発注先"
                            v-model.trim="qfilter.purchaseorder_flag"
                            maxlength="100"
                            name="purchaseorder_flag"
                        />

                        <OpCheckbox
                            label="ユーザ"
                            v-model.trim="qfilter.user_flag"
                            maxlength="100"
                            name="user_flag"
                        />
                    </div>
                </div>

                <fieldset class="deposit">
                    <legend>入金</legend>
                    <div style="display:flex;">
                        <div class="item deposit-cutoff-day">
                            <OpNumberTextField
                                label="入金締日"
                                v-model="qfilter.deposit_cutoff_day"
                                min="1"
                                max="31"
                                name="deposit_cutoff_day"
                                prefix="毎月"
                                prefixwidth="2em"
                                suffix="日"
                                suffixwidth="1em"
                                maxwidth="8em"
                            />
                        </div>
                        <div class="item deposit-term">
                            <OpNumberTextField
                                label="入金サイト"
                                v-model="qfilter.deposit_term"
                                min="1"
                                max="12"
                                name="deposit_term"
                                suffix="ヵ月"
                                suffixwidth="2em"
                                maxwidth="7em"
                            />
                        </div>
                        <div class="item deposit-day">
                            <OpNumberTextField
                                label="入金日"
                                v-model="qfilter.deposit_day"
                                min="1"
                                max="31"
                                name="deposit_day"
                                suffix="日"
                                suffixwidth="1em"
                                maxwidth="6em"
                            />
                            <OpCheckbox
                                label="後日シフト"
                                v-model.trim="qfilter.deposit_later_date_flag"
                                name="deposit_later_date_flag"
                            />
                        </div>
                        <div class="item deposit-bank">
                            <OpSelectBank
                                label="入金口座（銀行）"
                                v-model="qfilter.deposit_bank"
                                maxlength="80"
                                name="deposit_bank"
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset class="payment">
                    <legend>支払</legend>
                    <div style="display:flex;">
                        <div class="item payment-cutoff-day">
                            <OpNumberTextField
                                label="締日"
                                v-model="qfilter.payment_cutoff_day"
                                min="1"
                                max="31"
                                name="payment_cutoff_day"
                                prefix="毎月"
                                prefixwidth="2em"
                                suffix="日"
                                suffixwidth="1em"
                                maxwidth="8em"
                            />
                        </div>
                        <div class="item payment-term">
                            <OpNumberTextField
                                label="支払いサイト"
                                v-model="qfilter.payment_term"
                                min="1"
                                max="12"
                                name="payment_term"
                                suffix="ヵ月"
                                suffixwidth="2em"
                                maxwidth="7em"
                            />
                        </div>
                        <div class="item payment-day">
                            <OpNumberTextField
                                label="支払日"
                                v-model="qfilter.payment_day"
                                min="1"
                                max="31"
                                name="payment_day"
                                suffix="日"
                                suffixwidth="1em"
                                maxwidth="6em"
                            />
                            <OpCheckbox
                                label="後日シフト"
                                v-model.trim="qfilter.payment_later_date_flag"
                                name="payment_later_date_flag"
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="control-box">
                <button class="control-button" @click="cancel">
                    キャンセル
                </button>
                <button class="control-button" @click="setFilter">
                    設定
                </button>
                <button class="control-button" @click="clearFilter">
                    クリア
                </button>
            </div>
        </OpModal>
        <div
            style="float:right; position:sticky; top:80px; margin:10px; z-index:1;"
        >
            <MopSortButton @click="showSortModal = true" />
        </div>
        <div style="position:relative; z-index:0;">
            <div class="alegend">
                ★ ：有効 ／ ☆ ：無効（有効開始前） ／ - ：無効（期限切れ）
            </div>
            <table style="margin:0 auto;">
                <tbody
                    @click="showView(data)"
                    v-for="(data, ix) in payload_data"
                    :key="ix"
                >
                    <tr :class="getExdivClass(data)">
                        <th class="rownum" rowspan="2">
                            {{ ix + 1 }}
                        </th>
                        <td rowspan="2">
                            {{ getExdivMark(data) }}
                        </td>

                        <td @click="showView(data)">
                            <div class="title">
                                取引先ID
                            </div>
                            <div class="value">
                                {{ data.customer_id }}
                            </div>
                        </td>

                        <td>
                            <div class="title">
                                取引先CD
                            </div>
                            <div class="value">
                                {{ data.customer_code }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                取引先名
                            </div>
                            <div class="value">
                                {{ data.customer_name_long }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                取引先略称
                            </div>
                            <div class="value">
                                {{ data.customer_name_short }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                取引先Index
                            </div>
                            <div
                                class="value"
                                style="width:20rem;text-overflow: ellipsis;overflow: hidden;"
                            >
                                {{ data.customer_index }}
                            </div>
                        </td>

                        <td rowspan="2">
                            <div class="title">
                                取引先区分
                            </div>
                            <div class="value">
                                <div v-if="data.salesorder_flag">受注</div>
                                <div v-if="data.purchaseorder_flag">発注</div>
                                <div v-if="data.user_flag">ユーザ</div>
                            </div>
                        </td>
                    </tr>
                    <tr :class="getExdivClass(data)">
                        <td colspan="3">
                            <div class="title">
                                有効期間
                            </div>
                            <div class="value">
                                {{ data.start_date | date }} <wbr /> -
                                {{ data.end_date | date }}
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="title">備考</div>
                            <div class="value">
                                {{ data.customer_note }}
                            </div>
                        </td>

                        <!-- <td colspan="2">
                            <div class="title">入金情報</div>
                            <div class="item">
                                <div class="title">締日</div>
                                <div class="value">
                                    {{ data.deposit_cutoff_day }}日
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">サイト</div>
                                <div class="value">
                                    {{ data.deposit_term }}ヵ月
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">期日</div>
                                <div class="value">
                                    {{ data.deposit_day }}日
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">後日シフト</div>
                                <div class="value">
                                    {{ data.deposit_later_date_flag | flag }}
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">銀行</div>
                                <div class="value">
                                    {{ getBankName(data.deposit_bank_code) }}
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="title">支払情報</div>
                            <div class="item">
                                <div class="title">締日</div>
                                <div class="value">
                                    {{ data.payment_cutoff_day }}日
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">サイト</div>
                                <div class="value">
                                    {{ data.payment_term }}ヵ月
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">期日</div>
                                <div class="value">
                                    {{ data.payment_day }}日
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">後日シフト</div>
                                <div class="value">
                                    {{ data.payment_later_date_flag | flag }}
                                </div>
                            </div>
                        </td> -->
                    </tr>
                    <!-- <tr>
                        <td colspan="6">
                            <div class="title">備考</div>
                            <div class="value">
                                {{ data.customer_note }}
                            </div>
                        </td>
                    </tr> -->
                </tbody>
            </table>
        </div>
        <OpModal
            :title="viewDialogTitle"
            v-if="showViewDialog"
            :alterButton="hasTicket('cust-u')"
            :changeButton="hasTicket('cust-u')"
            :copyButton="hasTicket('cust-c')"
            :removeButton="hasTicket('cust-d')"
            v-slot="{ close }"
            @close="showViewDialog = false"
            @clickAlter="showAlterEditor"
            @clickChange="showChangeEditor"
            @clickRemove="showDeleteConfirm = true"
            @clickCopy="showCopyEditor"
        >
            <CustomerView
                name="customerView"
                v-model="selectedData"
                @fin="close"
                @loadedData="loadedSelectedData"
            />
        </OpModal>
        <OpModal
            :title="editDialogTitle"
            v-if="showEditDialog"
            @close="showEditDialog = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <CustomerEditor
                v-model="selectedData"
                name="customerEditor"
                :mode="editMode"
                @fin="close"
                @step="step"
                @updated-data="updatedData"
                @created-data="createdData"
                @moveto="showView"
            />
        </OpModal>

        <OpModal
            v-if="showDeleteConfirm"
            title="取引先の削除"
            @close="showDeleteConfirm = false"
            v-slot="{ close, step }"
            :steps="['確認', '完了']"
        >
            <OpConfirm
                confirm="削除してもよろしいですか？"
                complete="削除しました。"
                :okfunction="deleteCustomer"
                @fin="close"
                @step="step"
            ></OpConfirm>
        </OpModal>
        <MopSortSelectorModal
            v-if="showSortModal"
            title="並べ替え"
            :columns="{
                id: '取引先ID',
                start: '有効期間（開始）',
                end: '有効期間（終了）',
                code: '取引先コード',
                namel: '取引先名',
                names: '取引先略称'
            }"
            :orders="orders"
            @cancel="showSortModal = false"
            @decide="sortList"
        />
    </div>
</template>

<script>
import CustomerView from "@/views/customer/CustomerView.vue";
import CustomerEditor from "@/views/customer/CustomerEditor.vue";
import OpSearchTextField from "@/components/OpSearchTextField.vue";
// import OpModalForView from "@/components/OpModalForView.vue";
import IconBase from "@/components/icons/IconBase.vue";
import IconFilter from "@/components/icons/IconFilter.vue";
import OpDateRangeTextField from "@/components/OpDateRangeTextField.vue";
import OpCheckbox from "@/components/OpCheckbox.vue";
import OpNumberTextField from "@/components/OpNumberTextField.vue";
import OpConfirm from "@/components/OpConfirm.vue";
import MopSortSelectorModal from "@/components/MopSortSelectorModal.vue";
import OpSelectBank from "@/components/OpSelectBank.vue";
import MopSortButton from "@/components/buttons/MopSortButton.vue";

import DateUtils from "@/DateUtils.js";

// const axios = require("axios");

export default {
    name: "CustomerList",
    components: {
        CustomerView,
        CustomerEditor,
        OpSearchTextField,
        // OpModalForView,
        IconBase,
        IconFilter,
        OpDateRangeTextField,
        OpCheckbox,
        OpNumberTextField,
        OpConfirm,
        MopSortSelectorModal,
        OpSelectBank,
        MopSortButton
    },
    data: function() {
        return {
            showViewDialog: false,
            viewDialogTitle: "",
            selectedData: null,
            showEditDialog: false,
            editDialogTitle: "",
            showFilter: false,
            query: {
                // rdate: new Date(),
                rdiv: {
                    future: false,
                    current: false,
                    past: false
                }
            },
            qfilter: {},
            editMode: "",
            payload_data: [],
            showDeleteConfirm: false,
            showSortModal: false,
            orders: [
                { key: "id", desc: 0 },
                { key: "start", desc: 0 }
            ]
        };
    },
    methods: {
        showEditor: function(data) {
            this.editMode = "create";
            this.selectedData = data;
            this.editDialogTitle = "取引先登録 ( ID : 自動採番 )";
            this.showEditDialog = true;
        },
        showAlterEditor: function() {
            let data = this.selectedData;
            this.editMode = "update";
            this.editDialogTitle =
                "取引先情報の修正  ( ID : " + data.customer_id + " )";
            this.showEditDialog = true;
        },
        showChangeEditor: function() {
            let data = this.selectedData;
            this.editMode = "change";
            this.editDialogTitle =
                "取引先情報の変更  ( ID : " + data.customer_id + " )";
            this.showEditDialog = true;
        },
        showCopyEditor: function() {
            this.showEditor(this.selectedData);
        },
        showView: function(data) {
            this.selectedData = data;
            this.viewDialogTitle = "取引先  ( ID : " + data.customer_id + " )";
            this.showViewDialog = true;
        },
        showFilterPanel: function() {
            this.qfilter = { ...this.query };
            this.showFilter = true;
        },
        // findcustomer: async function(ddata) {
        //     this.readDataToObj(this.makeurl("cust"), ddata);
        // },
        sortList: function(orders) {
            this.showSortModal = false;
            this.orders = orders;
            this.search();
        },
        search: function() {
            // let params = null;
            // if (!words) {
            //     words = this.query.words;
            // }
            // if (words) {
            //     params = { words: words };
            // }
            let params = this.makeQueryParams();
            // this.readDataToObj(this.makeurl("cust", params), this.payload_data);
            return this.readDataToArray(this.makeurl("cust", params), list => {
                this.payload_data = list;
            });
        },
        loadedSelectedData: function(data) {
            this.selectedData = data;
        },
        updatedData: function(value) {
            this.selectedData = value;
            this.search();
        },
        createdData: function() {
            this.search();
        },
        cancel() {
            this.showFilter = false;
            this.qfilter = { ...this.query };
        },
        setFilter() {
            let rdate = this.query.rdate;
            let rdiv = this.query.rdiv;
            this.query = { ...this.qfilter };
            this.query.rdate = rdate;
            this.query.rdiv = rdiv;
            this.showFilter = false;
            this.search();
        },
        clearFilter: function() {
            let w = this.query.words;
            this.qfilter = {
                words: w
            };
        },
        makeQueryParams: function() {
            let params = {};
            this.putParam(params, "words");
            this.putParamDataRange(params, "start_date");
            this.putParamDataRange(params, "end_date");
            this.putParam(params, "salesorder_flag");
            this.putParam(params, "purchaseorder_flag");
            this.putParam(params, "user_flag");
            this.putParam(params, "deposit_cutoff_day");
            this.putParam(params, "deposit_term");
            this.putParam(params, "deposit_day");
            this.putParam(params, "deposit_later_date_flag");
            if (this.query["deposit_bank"]) {
                params["deposit_bank_code"] = this.query[
                    "deposit_bank"
                ].bank_code;
            }
            this.putParam(params, "deposit_bank_code");
            this.putParam(params, "payment_cutoff_day");
            this.putParam(params, "payment_term");
            this.putParam(params, "payment_day");
            this.putParam(params, "payment_later_date_flag");

            this.putParamDate(params, "rdate");

            let rdiv = 0;
            if (this.query.rdiv.past) {
                rdiv += 1;
            }
            if (this.query.rdiv.current) {
                rdiv += 2;
            }
            if (this.query.rdiv.future) {
                rdiv += 4;
            }
            params["rdiv"] = rdiv;

            if (this.orders) {
                if (!params) {
                    params = {};
                }
                let p = "";
                for (const ods of this.orders) {
                    p += "," + ods.key + ":" + ods.desc;
                }
                if (p.length > 0) {
                    params["orders"] = p.substring(1);
                }
            }
            return params;
        },
        putParam: function(params, pkey, vkey) {
            let p = this.query[pkey];
            if (p) {
                if (vkey) {
                    p = p[vkey];
                }
                params[pkey] = p;
            }
        },
        putParamDate: function(params, pkey) {
            let rd = this.query[pkey];
            let pm = "";
            if (rd) {
                pm = this.dateTo8Str(rd);
                if (pm) {
                    params[pkey] = pm;
                }
            }
        },
        putParamDataRange: function(params, pkey) {
            let rd = this.query[pkey];
            let pm = "";
            if (rd) {
                if (rd.start) {
                    pm = this.dateTo8Str(rd.start);
                }
                pm += "-";
                if (rd.end) {
                    pm += this.dateTo8Str(rd.end);
                }
                if (pm != "" && pm != "-") {
                    params[pkey] = pm;
                }
            }
        },
        deleteCustomer: function(func) {
            let d = this.selectedData;
            let data = this.convertToRequestData(d);
            let url = this.makeurl("cust", data.customer_id, data.start_date);
            this.deleteData(
                url,
                data,
                () => {
                    func();
                    this.showViewDialog = false;
                    this.selectedData = null;
                    this.search();
                },
                () => {
                    this.showDeleteConfirm = false;
                }
            );
        },
        getBankName: function(code) {
            let ret = "";
            let bk = this.getBank(code);
            if (bk) {
                ret = bk.bank_name_short;
            }
            return ret;
        },
        getExdivMark: function(data) {
            let ret = "★";
            let c = this.compareExdate(data);
            if (c > 0) {
                ret = "☆";
            } else if (c < 0) {
                ret = "-";
            }
            return ret;
        },
        getExdivClass: function(data) {
            let ret = "";
            let c = this.compareExdate(data);
            if (c > 0) {
                ret = "future";
            } else if (c < 0) {
                ret = "past";
            }
            return ret;
        },
        compareExdate: function(data) {
            let ret = 0;
            let now = DateUtils.toString8(DateUtils.getNowDate());
            if (data.end_date && DateUtils.toString8(data.end_date) < now) {
                ret = -1;
            } else if (
                data.start_date &&
                DateUtils.toString8(data.start_date) > now
            ) {
                ret = 1;
            }
            return ret;
        }
    },
    created: async function() {
        await this.loadbanklist();
        await this.search();
    }
};
</script>

<style scoped>
.control-box {
    display: grid;
    border-top: 1px solid var(--border-color);
    padding: 10px 0;
    grid-template-columns: 200px 1fr 200px;
    justify-items: center;
}
/* .view-header {
    position: sticky;
    top: 0;
    padding: 0px 100px 5px 100px;
    background-color: var(--glass-pane-bg-color);
    z-index: 1; 
} */
/* .view-header > div {
    margin-right: auto;
    margin-left: auto;

} */

/* .add-button {
    width: 30px;
    height: 30px;
    padding: 5px;
} */

thead {
    display: none;
}
td {
    vertical-align: top;
    border-right-style: none;
    border-left-style: none;
}
td .title {
    font-size: 0.5em;
    display: block;
}
td .value {
    padding: 3px 0;
}
td .value .id {
    font-size: 0.75em;
    display: inline-block;
}
td .value .id:after {
    content: " : ";
}
td .item {
    display: inline-block;
    vertical-align: top;
    padding: 2px 10px;
}
tbody {
    cursor: pointer;
}
tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}
tbody tr td {
    border-style: none;
}

tbody {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}
.alegend {
    margin: 15px auto;
    text-align: center;
    font-size: 0.75rem;
    color: var(--color-gray);
}

.future {
    opacity: 0.5;
}
.past {
    opacity: 0.5;
}

.item {
    padding: 10px;
}
.cust-attr .item > * {
    padding: 0 10px;
}
</style>
