<template>
    <div class="view-page">
        <div class="content  cust-panel">
            <!-- <div class="item cust-id">
                <div class="title">取引先ID</div>
                <span class="value">
                    {{ d.customer_id }}
                </span>
            </div> -->

            <div class="item cust-code">
                <div class="title">取引先CD</div>
                <span class="value">{{ d.customer_code }}</span>
            </div>
            <div class="item valid-date">
                <div class="title">有効期間</div>
                <span class="value">
                    {{ d.start_date | date }} - {{ d.end_date | date }}</span
                >
            </div>
            <div class="item cust-name-short">
                <div class="title">取引先名（短縮）</div>
                <span class="value">{{ d.customer_name_short }}</span>
            </div>
            <div class="item cust-name-long">
                <div class="title">取引先名（正式）</div>
                <span class="value">{{ d.customer_name_long }}</span>
            </div>
            <div class="item cust-index">
                <div class="title">取引先インデックス</div>
                <span class="value">{{ d.customer_index }}</span>
            </div>

            <div class="item flags">
                <div class="title">区分</div>
                <span class="value">
                    <span v-if="d.salesorder_flag">受注先</span>
                    <span v-if="d.purchaseorder_flag">発注先</span>
                    <span v-if="d.user_flag">ユーザ</span>
                </span>
            </div>

            <div class="item deposit">
                <fieldset>
                    <legend>入金</legend>
                    <div class="content content-deposit">
                        <div class="item deposit-cutoff-day">
                            <div class="title">入金締日</div>
                            <span class="value"
                                >毎月 {{ d.deposit_cutoff_day }} 日</span
                            >
                        </div>
                        <div class="item deposit-term">
                            <div class="title">入金サイト</div>
                            <span class="value">{{ d.deposit_term }} ヵ月</span>
                        </div>
                        <div class="item deposit-day">
                            <div class="title">入金日</div>
                            <span class="value">{{ d.deposit_day }} 日</span>
                        </div>
                        <div class="item deposit-flag">
                            <span v-if="d.deposit_later_date_flag">
                                後日シフト
                            </span>
                        </div>
                        <div class="item deposit-bank">
                            <div class="title">入金口座（銀行）</div>
                            <span class="value">
                                <span class="code">{{
                                    d.deposit_bank_code
                                }}</span>
                                {{ getBankName(d.deposit_bank_code) }}</span
                            >
                        </div>
                    </div>
                </fieldset>
            </div>

            <div class="item payment">
                <fieldset>
                    <legend>支払</legend>
                    <div class="content content-payment">
                        <div class="item payment-cutoff-day">
                            <div class="title">支払締日</div>
                            <span class="value"
                                >毎月 {{ d.payment_cutoff_day }} 日</span
                            >
                        </div>
                        <div class="item payment-term">
                            <div class="title">支払いサイト</div>
                            <span class="value">{{ d.payment_term }} ヵ月</span>
                        </div>
                        <div class="item payment-day">
                            <div class="title">支払日</div>
                            <span class="value">{{ d.payment_day }} 日</span>
                        </div>
                        <div class="item payment-flag">
                            <span v-if="d.payment_later_date_flag">
                                後日シフト
                            </span>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="item cust-note">
                <div class="title">備考</div>
                <span class="value">{{ d.customer_note }}</span>
            </div>
        </div>

        <div class="sysinfo">
            <div class="item">
                <div class="title">作成</div>
                <span class="value">{{ d.create_timestamp }}</span>
                <span class="value">{{ d.create_account_id }}</span>
            </div>
            <div class="item" v-if="d.update_timestamp">
                <div class="title">変更</div>
                <span class="value">{{ d.update_timestamp }}</span>
                <span class="value">{{ d.update_account_id }}</span>
            </div>
            <div class="item version">
                <div class="title">version</div>
                <span class="value">{{ d.row_version }}</span>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require("axios");

export default {
    name: "CustomerView",

    model: {
        prop: "keys"
    },
    props: {
        keys: Object
    },
    watch: {
        keys: function(val) {
            this.d = val;
        }
    },
    data: function() {
        return {
            d: this.keys,
            showEditDialog: false
        };
    },
    methods: {
        updated: function(value) {
            this.d = value;
        },
        editData: function() {
            this.showEditDialog = true;
        },
        deleteData: function() {
            this.cdialog.message =
                this.d.customer_id + "/" + this.d.start_date + " を削除します";
            this.cdialog.step = 1;
            this.cdialog.execfunc = () => {
                this.cdialog.step = 2;
                axios
                    .delete(
                        this.makeurl(
                            "cust",
                            this.d.customer_id,
                            this.d.start_date
                        ),
                        { data: this.d }
                    )
                    .then(() => {
                        this.cdialog.step = 3;
                        this.findcustomer(this.payload_data);
                        this.currentStep = 0;
                    })
                    .catch(err => {
                        if (err.response) {
                            alert(err.response);
                        }
                    });
            };
        },
        closeDialog: function() {
            this.cdialog.step = 0;
        },
        readcustomer: async function(id, strdate) {
            axios.get(this.makeurl("cust", id, strdate)).then(response => {
                this.d = this.convertFromResponseData(response.data);
                this.$emit("loadedData", this.d);
            });
        },
        getBankName: function(code) {
            let ret = "";
            let bk = this.getBank(code);
            if (bk) {
                ret = bk.bank_name_short;
            }
            return ret;
        }
    },
    created: async function() {
        await this.loadbanklist();
        this.readcustomer(this.keys.customer_id, this.keys.start_date);
    }
};
</script>

<style scoped>
.cust-panel {
    position: relative;
    display: grid;
    grid-template-areas:
        "valid-date          valid-date      valid-date      cust-code         cust-code      empty0        empty0"
        "cust-name-long      cust-name-long      cust-name-long   cust-name-long  cust-name-long  cust-name-short   cust-name-short"
        "cust-index          cust-index          cust-index       cust-index      cust-index      cust-index        cust-index"
        "flags               flags               flags        flags          flags          flags          flags"
        "deposit     deposit  deposit     deposit     deposit    deposit      deposit"
        "payment  payment  payment     payment     payment    payment      payment "
        "cust-note           cust-note           cust-note        cust-note       cust-note       cust-note         cust-note";

    grid-row-gap: 10px;
    grid-column-gap: 30px;
}

.content-deposit {
    position: relative;
    display: grid;
    grid-template-areas: "deposit-cutoff-day  deposit-term     deposit-day     deposit-flag    deposit-bank  ";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
}
.content-payment {
    position: relative;
    display: grid;
    grid-template-areas: "payment-cutoff-day  payment-term     payment-day     payment-flag";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
}

.cust-panel .cust-id {
    grid-area: cust-id;
}
.cust-panel .cust-code {
    grid-area: cust-code;
}
.cust-panel .valid-date {
    grid-area: valid-date;
}
.cust-panel .cust-name-short {
    grid-area: cust-name-short;
}
.cust-panel .cust-name-long {
    grid-area: cust-name-long;
}
.cust-panel .cust-name-long > * {
    width: 100%;
}
.cust-panel .cust-index {
    grid-area: cust-index;
}
.cust-panel .cust-index > * {
    width: 100%;
}

.cust-panel .flags {
    grid-area: flags;
}
.cust-panel .flags * {
    padding-right: 10px;
}
.cust-panel .purchaseorder-flag {
    grid-area: purchaseorder-flag;
}
.cust-panel .user-flag {
    grid-area: user-flag;
}
.cust-panel .deposit {
    grid-area: deposit;
}
.cust-panel .deposit-cutoff-day {
    grid-area: deposit-cutoff-day;
}
.cust-panel .deposit-term {
    grid-area: deposit-term;
}
.cust-panel .deposit-day {
    grid-area: deposit-day;
}
.cust-panel .deposit-flag {
    grid-area: deposit-flag;
    align-self: center;
}
.cust-panel .deposit-bank {
    grid-area: deposit-bank;
}
.cust-panel .payment {
    grid-area: payment;
}
.cust-panel .payment-cutoff-day {
    grid-area: payment-cutoff-day;
}
.cust-panel .payment-term {
    grid-area: payment-term;
}
.cust-panel .payment-day {
    grid-area: payment-day;
}
.cust-panel .payment-flag {
    grid-area: payment-flag;
    align-self: center;
}
.cust-panel .cust-note {
    grid-area: cust-note;
}
.cust-panel .cust-note > * {
    width: 100%;
}

/* --- */
.noselected {
    text-decoration: line-through;
}
.selected::before {
    content: "✔ ";
}

/* --- */
.sysinfo {
    font-size: 0.75rem;
    text-align: right;
}
.sysinfo .item {
    display: inline-block;
    padding: 1rem;
}
.sysinfo .item .title {
    display: inline-block;
    vertical-align: top;
}
.sysinfo .item .title:after {
    content: " : ";
}
.sysinfo .item .value {
    display: inline-block;
    padding-left: 0.5rem;
}

.sysinfo .version {
    display: none;
}
</style>
